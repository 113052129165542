import React from "react"
import Layout from "../components/layout"
import CompanyJSON from "../data/company.json"
import "../styles/longform.css"
import HeaderH1 from "../components/headerH1";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";

export default function Privacy() {
  return (
    <>
      <GatsbySeo title="Privacy Policy"
                 description={`As we are a german company, please refer to this German version of our Privacy Policy.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true">
        <section className="bg-white py-20">
          <div className="container max-w-5xl mx-auto m-8">
            <HeaderH1 headerText="Privacy Policy"/>
          </div>

          <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl longform-text">

            <p className="text-center">As we are a german company, please refer to this German version of our Privacy
              Policy.</p>

            <h2>Einleitung</h2>
            <p>
              Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
              welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten“
              bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die
              Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen
              personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen
              als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
              innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
              (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
            </p>
            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
            <p>Stand: 25. Januar 2021</p>
            <h2>Inhaltsübersicht</h2>
            <ul className="index">
              <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
              <li>
                <a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a>
              </li>
              <li><a className="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
              <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
              <li>
                <a className="index-link" href="#m25"
                >Übermittlung und Offenbarung von personenbezogenen Daten</a
                >
              </li>
              <li>
                <a className="index-link" href="#m24">Datenverarbeitung in Drittländern</a>
              </li>
              <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
              <li>
                <a className="index-link" href="#m317"
                >Kommerzielle und geschäftliche Leistungen</a
                >
              </li>
              <li><a className="index-link" href="#m326">Zahlungsdienstleister</a></li>
              <li>
                <a className="index-link" href="#m225"
                >Bereitstellung des Onlineangebotes und Webhosting</a
                >
              </li>
              <li>
                <a className="index-link" href="#m367"
                >Registrierung, Anmeldung und Nutzerkonto</a
                >
              </li>
              <li><a className="index-link" href="#m104">Blogs und Publikationsmedien</a></li>
              <li><a className="index-link" href="#m182">Kontaktaufnahme</a></li>
              <li><a className="index-link" href="#m391">Kommunikation via Messenger</a></li>
              <li>
                <a className="index-link" href="#m735"
                >Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing</a
                >
              </li>
              <li><a className="index-link" href="#m29">Cloud-Dienste</a></li>
              <li>
                <a className="index-link" href="#m17"
                >Newsletter und elektronische Benachrichtigungen</a
                >
              </li>
              <li>
                <a className="index-link" href="#m638"
                >Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</a
                >
              </li>
              <li><a className="index-link" href="#m408">Umfragen und Befragungen</a></li>
              <li>
                <a className="index-link" href="#m263"
                >Webanalyse, Monitoring und Optimierung</a
                >
              </li>
              <li><a className="index-link" href="#m264">Onlinemarketing</a></li>
              <li>
                <a className="index-link" href="#m136"
                >Präsenzen in sozialen Netzwerken (Social Media)</a
                >
              </li>
              <li>
                <a className="index-link" href="#m328"
                >Plugins und eingebettete Funktionen sowie Inhalte</a
                >
              </li>
              <li>
                <a className="index-link" href="#m723"
                >Planung, Organisation und Hilfswerkzeuge</a
                >
              </li>
              <li><a className="index-link" href="#m12">Löschung von Daten</a></li>
              <li>
                <a className="index-link" href="#m15"
                >Änderung und Aktualisierung der Datenschutzerklärung</a
                >
              </li>
              <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
              <li><a className="index-link" href="#m42">Begriffsdefinitionen</a></li>
            </ul>
            <h2 id="m3">Verantwortlicher</h2>
            <p>{CompanyJSON.privacyOfficer}<br/>{CompanyJSON.companyName}<br/>{CompanyJSON.street}<br/>{CompanyJSON.postcode} {CompanyJSON.city}<br/>{CompanyJSON.country}
            </p>
            <p>
              <strong>Vertretungsberechtigte Personen:</strong> {CompanyJSON.representatives}
              La
            </p>
            <p>
              <strong>E-Mail-Adresse:</strong>
              <a href={"mailto:" + CompanyJSON.privacyEmail}>{CompanyJSON.privacyEmail}</a>
            </p>
            <p>
              <strong>Impressum:</strong>
              <a href="https://www.linkfive.io/imprint" target="_blank" rel="noreferrer"
              >https://www.linkfive.io/imprint</a
              >
            </p>
            <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
            <p>
              Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die
              Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.
            </p>
            <h3>Arten der verarbeiteten Daten</h3>
            <ul>
              <li>
                Event-Daten (Facebook) ("Event-Daten" sind Daten, die z. B. via
                Facebook-Pixel (via Apps oder auf anderen Wegen) von uns an Facebook
                übermittelt werden können und sich auf Personen oder deren Handlungen
                beziehen; Zu den Daten gehören z. B. Angaben über Besuche auf Websites,
                Interaktionen mit Inhalten, Funktionen, Installationen von Apps, Käufe von
                Produkten, etc.; die Event-Daten werden zwecks Bildung von Zielgruppen für
                Inhalte und Werbeinformationen (Custom Audiences) verarbeitet; Event Daten
                beinhalten nicht die eigentlichen Inhalte (wie z. B. verfasste Kommentare),
                keine Login-Informationen und keine Kontaktinformationen (also keine Namen,
                E-Mail-Adressen und Telefonnummern). Event Daten werden durch Facebook nach
                maximal zwei Jahren gelöscht, die aus ihnen gebildeten Zielgruppen mit der
                Löschung unseres Facebook-Kontos).
              </li>
              <li>Bestandsdaten (z.B. Namen, Adressen).</li>
              <li>Inhaltsdaten (z.B. Eingaben in Onlineformularen).</li>
              <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
              <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
              <li>
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten).
              </li>
              <li>
                Sozialdaten (Daten, die dem Sozialgeheimnis unterliegen und z.B. von
                Sozialversicherungsträgern, Sozialhilfeträger oder Versorgungsbehörden
                verarbeitet werden.).
              </li>
              <li>
                Standortdaten (Angaben zur geografischen Position eines Gerätes oder einer
                Person).
              </li>
              <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
              <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
            </ul>
            <h3>Kategorien betroffener Personen</h3>
            <ul>
              <li>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</li>
              <li>Geschäfts- und Vertragspartner.</li>
              <li>Interessenten.</li>
              <li>Kommunikationspartner.</li>
              <li>Kunden.</li>
              <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
            </ul>
            <h3>Zwecke der Verarbeitung</h3>
            <ul>
              <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
              <li>Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).</li>
              <li>Büro- und Organisationsverfahren.</li>
              <li>Content Delivery Network (CDN).</li>
              <li>
                Cross-Device Tracking (geräteübergreifende Verarbeitung von Nutzerdaten für
                Marketingzwecke).
              </li>
              <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
              <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
              <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
              <li>Kontaktanfragen und Kommunikation.</li>
              <li>Profiling (Erstellen von Nutzerprofilen).</li>
              <li>Remarketing.</li>
              <li>
                Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender
                Besucher).
              </li>
              <li>Sicherheitsmaßnahmen.</li>
              <li>
                Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
                Cookies).
              </li>
              <li>Servermonitoring und Fehlererkennung.</li>
              <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
              <li>Verwaltung und Beantwortung von Anfragen.</li>
              <li>
                Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten
                Zielgruppen oder sonstige Ausgabe von Inhalten).
              </li>
            </ul>
            <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
            <p>
              Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung
              (DSGVO), auf deren Basis wir die personenbezogenen Daten verarbeiten, mit.
              Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die nationalen
              Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten können.
              Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein,
              teilen wir Ihnen diese in der Datenschutzerklärung mit.
            </p>
            <ul>
              <li>
                <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die
                betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
                betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
                mehrere bestimmte Zwecke gegeben.
              </li>
              <li>
                <strong
                >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
                  b. DSGVO)</strong
                >
                - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                Vertragspartei die betroffene Person ist, oder zur Durchführung
                vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
                Person erfolgen.
              </li>
              <li>
                <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong>
                - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
                erforderlich, der der Verantwortliche unterliegt.
              </li>
              <li>
                <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> -
                Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
                Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
                Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die
                den Schutz personenbezogener Daten erfordern, überwiegen.
              </li>
            </ul>
            <p>
              <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu
              den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
              Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das
              Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
              Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
              insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung,
              zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
              personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
              Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
              einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für
              Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
              auf die Begründung, Durchführung oder Beendigung von
              Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner
              können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
              gelangen.
            </p>
            <h2 id="m27">Sicherheitsmaßnahmen</h2>
            <p>
              Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des
              Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der
              Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
              Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
              Freiheiten natürlicher Personen geeignete technische und organisatorische
              Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
            </p>
            <p>
              Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
              Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und
              elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
              der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
              Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung
              von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
              Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware,
              Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
              Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
            </p>
            <p>
              <strong>Kürzung der IP-Adresse</strong>: Sofern IP-Adressen von uns oder von
              den eingesetzten Dienstleistern und Technologien verarbeitet werden und die
              Verarbeitung einer vollständigen IP-Adresse nicht erforderlich ist, wird die
              IP-Adresse gekürzt (auch als "IP-Masking" bezeichnet). Hierbei werden die
              letzten beiden Ziffern, bzw. der letzte Teil der IP-Adresse nach einem Punkt
              entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung der IP-Adresse soll
              die Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder
              wesentlich erschwert werden.
            </p>
            <p>
              <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot
              übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie
              erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
              Adresszeile Ihres Browsers.
            </p>
            <h2 id="m25">Übermittlung und Offenbarung von personenbezogenen Daten</h2>
            <p>
              Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass
              die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
              Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
              offengelegt werden. Zu den Empfängern dieser Daten können z.B.
              Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit IT-Aufgaben beauftragte
              Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite
              eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen
              Vorgaben und schließen insbesondere entsprechende Verträge bzw.
              Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer
              Daten ab.
            </p>
            <p>
              <strong>Datenübermittlung innerhalb der Organisation</strong>: Wir können
              personenbezogene Daten an andere Stellen innerhalb unserer Organisation
              übermitteln oder ihnen den Zugriff auf diese Daten gewähren. Sofern diese
              Weitergabe zu administrativen Zwecken erfolgt, beruht die Weitergabe der Daten
              auf unseren berechtigten unternehmerischen und betriebswirtschaftlichen
              Interessen oder erfolgt, sofern sie Erfüllung unserer vertragsbezogenen
              Verpflichtungen erforderlich ist oder wenn eine Einwilligung der Betroffenen
              oder eine gesetzliche Erlaubnis vorliegt.
            </p>
            <h2 id="m24">Datenverarbeitung in Drittländern</h2>
            <p>
              Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union
              (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
              Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der
              Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
              Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
              Vorgaben.
            </p>
            <p>
              Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich
              erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in
              Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen
              Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim
              Vorliegen von Zertifizierungen oder verbindlicher internen
              Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO, Informationsseite
              der EU-Kommission:
              <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                target="_blank" rel="noreferrer"
              >https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a
              >
              ).
            </p>
            <h2 id="m134">Einsatz von Cookies</h2>
            <p>
              Cookies sind Textdateien, die Daten von besuchten Websites oder Domains
              enthalten und von einem Browser auf dem Computer des Benutzers gespeichert
              werden. Ein Cookie dient in erster Linie dazu, die Informationen über einen
              Benutzer während oder nach seinem Besuch innerhalb eines Onlineangebotes zu
              speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen
              auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein
              Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner
              andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B.,
              wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert
              werden, auch als "Nutzer-IDs" bezeichnet)
            </p>
            <p>
              <strong
              >Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong
              >
            </p>
            <ul>
              <li>
                <strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong
                >&nbsp;Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
                Online-Angebot verlassen und seinen Browser geschlossen hat.
              </li>
              <li>
                <strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben auch
                nach dem Schließen des Browsers gespeichert. So kann beispielsweise der
                Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
                wenn der Nutzer eine Website erneut besucht. Ebenso können die Interessen
                von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet
                werden, in einem solchen Cookie gespeichert werden.
              </li>
              <li>
                <strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies werden von
                uns selbst gesetzt.
              </li>
              <li>
                <strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>:
                Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog.
                Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
              </li>
              <li>
                <strong
                >Notwendige (auch: essentielle oder unbedingt erforderliche)
                  Cookies:</strong
                >
                Cookies können zum einen für den Betrieb einer Webseite unbedingt
                erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu speichern
                oder aus Gründen der Sicherheit).
              </li>
              <li>
                <strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>:
                Ferner werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung
                eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten
                (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf
                einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile
                dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen
                Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h.,
                Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. Soweit wir
                Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie
                gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer
                Einwilligung.
              </li>
            </ul>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage
              wir Ihre personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt
              davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie
              in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der
              Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die
              mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten
              Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
              Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz
              von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
              erfüllen.
            </p>
            <p>
              <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten Angaben zur
              Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen eines sog.
              Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die Speicherdauer bis zu zwei
              Jahre betragen kann.
            </p>
            <p>
              <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>
              Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder
              gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine
              erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch
              Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out"
              bezeichnet). Sie können Ihren Widerspruch zunächst mittels der Einstellungen
              Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren
              (wobei hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes
              eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu
              Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten,
              vor allem im Fall des Trackings, über die Webseiten
              <a href="https://optout.aboutads.info" target="_blank" rel="noreferrer"
              >https://optout.aboutads.info</a
              >
              und
              <a href="https://www.youronlinechoices.com/" target="_blank" rel="noreferrer"
              >https://www.youronlinechoices.com/</a
              >
              erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der
              Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
            </p>
            <p>
              <strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung</strong
              >: Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in dessen
              Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im
              Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
              und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen werden
              können. Hierbei wird die Einwilligungserklärung gespeichert, um deren Abfrage
              nicht erneut wiederholen zum müssen und die Einwilligung entsprechend der
              gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann
              serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw.
              mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem
              Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller
              Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden
              Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren
              betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit
              dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B.
              welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser,
              System und verwendeten Endgerät gespeichert.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                (z.B. Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
            <p>
              Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und
              Interessenten (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen von
              vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen
              Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern (oder
              vorvertraglich), z.B., um Anfragen zu beantworten.
            </p>
            <p>
              Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur
              Sicherung unserer Rechte und zu Zwecken der mit diesen Angaben einhergehenden
              Verwaltungsaufgaben sowie der unternehmerischen Organisation. Die Daten der
              Vertragspartner geben wir im Rahmen des geltenden Rechts nur insoweit an
              Dritte weiter, als dies zu den vorgenannten Zwecken oder zur Erfüllung
              gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der betroffenen
              Personen erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
              sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und Rechtsberater,
              Zahlungsdienstleister oder Steuerbehörden). Über weitere Verarbeitungsformen,
              z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser
              Datenschutzerklärung informiert.
            </p>
            <p>
              Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den
              Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
              Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
              (z.B. Sternchen o.ä.), oder persönlich mit.
            </p>
            <p>
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
              vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4 Jahren, es sei
              denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B., solange
              sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (z.B.
              für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im Rahmen eines
              Auftrags durch den Vertragspartner offengelegt wurden, löschen wir
              entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
            </p>
            <p>
              Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen
              einsetzen, gelten im Verhältnis zwischen den Nutzern und den Anbietern die
              Geschäftsbedingungen und Datenschutzhinweise der jeweiligen Drittanbieter oder
              Plattformen.
            </p>
            <p>
              <strong>Kundenkonto</strong>: Vertragspartner können innerhalb unseres
              Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
              "Kundenkonto"). Falls die Registrierung eines Kundenkontos erforderlich ist,
              werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die
              Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich
              und können von Suchmaschinen nicht indexiert werden. Im Rahmen der
              Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos
              speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die
              Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu
              können.
            </p>
            <p>
              Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto
              betreffenden Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist aus
              gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre Daten bei
              erfolgter Kündigung des Kundenkontos zu sichern.
            </p>
            <p>
              <strong>Angebot von Software- und Plattformleistungen</strong>: Wir
              verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
              (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere
              vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter
              Interessen, um die Sicherheit unseres Angebotes gewährleisten und es
              weiterentwickeln zu können. Die erforderlichen Angaben sind als solche im
              Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
              gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung
              benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten
              zu können.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten
                (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B.
                besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und
                Vertragspartner, Kunden.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen
                und Kundenservice, Kontaktanfragen und Kommunikation, Büro- und
                Organisationsverfahren, Verwaltung und Beantwortung von Anfragen,
                Sicherheitsmaßnahmen.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art.
                6 Abs. 1 S. 1 lit. c. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m326">Zahlungsdienstleister</h2>
            <p>
              Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher
              Pflichten oder sonst auf Grundlage unserer berechtigten Interessen bieten wir
              den betroffenen Personen effiziente und sichere Zahlungsmöglichkeiten an und
              setzen hierzu neben Banken und Kreditinstituten weitere Zahlungsdienstleister
              ein (zusammenfassend "Zahlungsdienstleister").
            </p>
            <p>
              Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
              Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
              Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie
              die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
              erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten
              werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
              gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen
              Informationen, sondern lediglich Informationen mit Bestätigung oder
              Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens der
              Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese
              Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen
              wir auf die AGB und die Datenschutzhinweise der Zahlungsdienstleister.
            </p>
            <p>
              Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
              Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb der
              jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind. Wir
              verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung
              von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen), Kontaktdaten (z.B. E-Mail,
                Telefonnummern).
              </li>
              <li><strong>Betroffene Personen:</strong> Kunden, Interessenten.</li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen
                und Kundenservice.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
                Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>Apple Pay:</strong> Zahlungsdienstleistungen; Dienstanbieter: Apple
                Inc., Infinite Loop, Cupertino, CA 95014, USA; Website:
                <a href="https://www.apple.com/de/apple-pay/" target="_blank" rel="noreferrer"
                >https://www.apple.com/de/apple-pay/</a
                >; Datenschutzerklärung:
                <a href="https://www.apple.com/legal/privacy/de-ww/" target="_blank" rel="noreferrer"
                >https://www.apple.com/legal/privacy/de-ww/</a
                >.
              </li>
              <li>
                <strong>Google Pay:</strong> Zahlungsdienstleistungen; Dienstanbieter:
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; Website:
                <a href="https://pay.google.com/intl/de_de/about/" target="_blank" rel="noreferrer"
                >https://pay.google.com/intl/de_de/about/</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Klarna / Sofortüberweisung:</strong> Zahlungsdienstleistungen;
                Dienstanbieter: Klarna Bank AB (publ), Sveavägen 46, 111 34 Stockholm,
                Schweden; Website:
                <a href="https://www.klarna.com/de" target="_blank" rel="noreferrer"
                >https://www.klarna.com/de</a
                >; Datenschutzerklärung:
                <a href="https://www.klarna.com/de/datenschutz" target="_blank" rel="noreferrer"
                >https://www.klarna.com/de/datenschutz</a
                >.
              </li>
              <li>
                <strong>Mastercard:</strong> Zahlungsdienstleistungen; Dienstanbieter:
                Mastercard Europe SA, Chaussée de Tervuren 198A, B-1410 Waterloo, Belgien;
                Website:
                <a href="https://www.mastercard.de/de-de.html" target="_blank" rel="noreferrer"
                >https://www.mastercard.de/de-de.html</a
                >; Datenschutzerklärung:
                <a href="https://www.mastercard.de/de-de/datenschutz.html" target="_blank" rel="noreferrer"
                >https://www.mastercard.de/de-de/datenschutz.html</a
                >.
              </li>
              <li>
                <strong>Stripe:</strong> Zahlungsdienstleistungen; Dienstanbieter: Stripe,
                Inc., 510 Townsend Street, San Francisco, CA 94103, USA; Website:
                <a href="https://stripe.com/de" target="_blank" rel="noreferrer">https://stripe.com/de</a>;
                Datenschutzerklärung:
                <a href="https://stripe.com/de/privacy" target="_blank" rel="noreferrer"
                >https://stripe.com/de/privacy</a
                >.
              </li>
              <li>
                <strong>Visa:</strong> Zahlungsdienstleistungen; Dienstanbieter: Visa Europe
                Services Inc., Zweigniederlassung London, 1 Sheldon Square, London W2 6TT,
                GB; Website:
                <a href="https://www.visa.de" target="_blank" rel="noreferrer">https://www.visa.de</a>;
                Datenschutzerklärung:
                <a
                  href="https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html"
                  target="_blank" rel="noreferrer"
                >https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html</a
                >.
              </li>
            </ul>
            <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
            <p>
              Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen
              wir die Leistungen von einem oder mehreren Webhosting-Anbietern in Anspruch,
              von deren Servern (bzw. von ihnen verwalteten Servern) das Onlineangebot
              abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und
              Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste
              sowie Sicherheitsleistungen und technische Wartungsleistungen in Anspruch
              nehmen.
            </p>
            <p>
              Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten
              können alle die Nutzer unseres Onlineangebotes betreffenden Angaben gehören,
              die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu gehören
              regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von
              Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
              Onlineangebotes oder von Webseiten getätigten Eingaben.
            </p>
            <p>
              <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch
              genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
              sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der
              Empfänger sowie Absender als auch weitere Informationen betreffend den
              E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen
              E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
              Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass
              E-Mails im Internet grundsätzlich nicht verschlüsselt versendet werden. Im
              Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern
              kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht
              auf den Servern, von denen sie abgesendet und empfangen werden. Wir können
              daher für den Übertragungsweg der E-Mails zwischen dem Absender und dem
              Empfang auf unserem Server keine Verantwortung übernehmen.
            </p>
            <p>
              <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw.
              unser Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server
              (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name
              der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs,
              übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst
              Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
              Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
            </p>
            <p>
              Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
              werden, z.B., um eine Überlastung der Server zu vermeiden (insbesondere im
              Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
              anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.
            </p>
            <p>
              <strong>Content-Delivery-Network</strong>: Wir setzen ein
              "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit dessen Hilfe
              Inhalte eines Onlineangebotes, insbesondere große Mediendateien, wie Grafiken
              oder Programm-Skripte, mit Hilfe regional verteilter und über das Internet
              verbundener Server schneller und sicherer ausgeliefert werden können.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B. Eingaben in
                Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Content Delivery Network (CDN).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S.
                1 lit. f. DSGVO).
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>Amazon Web Services (AWS):</strong> Webhosting und infrastrukturelle
                Dienstleistungen; Dienstanbieter: Amazon Web Services, Inc., 410 Terry
                Avenue North, Seattle WA 98109, USA; Website:
                <a href="https://aws.amazon.com/de/" target="_blank" rel="noreferrer"
                >https://aws.amazon.com/de/</a
                >; Datenschutzerklärung:
                <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank" rel="noreferrer"
                >https://aws.amazon.com/de/privacy/?nc1=f_pr</a
                >.
              </li>
              <li>
                <strong>Google Cloud Platform (GCP):</strong> Webhosting und infrastrukturelle
                Dienstleistungen; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street Dublin 4, Ireland;
                Website:
                <a href="https://cloud.google.com/" target="_blank" rel="noreferrer"
                >https://cloud.google.com/</a
                >; Datenschutzerklärung:
                <a href="https://cloud.google.com/security/privacy" target="_blank" rel="noreferrer"
                >https://cloud.google.com/security/privacy</a
                >.
              </li>
            </ul>
            <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
            <p>
              Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den
              Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu Zwecken der
              Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung
              verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die
              Login-Informationen (Name, Passwort sowie eine E-Mail-Adresse). Die im Rahmen
              der Registrierung eingegebenen Daten werden für die Zwecke der Nutzung des
              Nutzerkontos und dessen Zwecks verwendet.
            </p>
            <p>
              Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie
              z.B. technische Änderungen, per E-Mail informiert werden. Wenn Nutzer ihr
              Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das
              Nutzerkonto, vorbehaltlich einer gesetzlichen Aufbewahrungspflicht, gelöscht.
              Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem
              Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der
              Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen.
            </p>
            <p>
              Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen
              sowie der Nutzung des Nutzerkontos speichern wir die IP-Adresse und den
              Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage
              unserer berechtigten Interessen als auch jener der Nutzer an einem Schutz vor
              Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an
              Dritte erfolgt grundsätzlich nicht, es sei denn, sie ist zur Verfolgung
              unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
              hierzu.
            </p>
            <p>
              <strong>Zwei-Faktor-Authentifizierung</strong>: Die
              Zwei-Faktor-Authentifizierung bietet eine zusätzliche Sicherheitsebene für Ihr
              Benutzerkonto und stellt sicher, dass nur Sie auf Ihr Konto zugreifen können,
              auch, wenn jemand anderes Ihr Passwort kennt.
            </p>
            <p>
              Zu diesem Zweck müssen Sie zusätzlich zu Ihrem Passwort eine weitere
              Authentifizierungsmaßnahme durchführen (z.B. einen an ein mobiles Gerät
              gesandten Code eingeben). Wir werden Sie über das von uns eingesetzte
              Verfahren informieren.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen
                und Kundenservice, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von
                Anfragen.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m104">Blogs und Publikationsmedien</h2>
            <p>
              Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und
              Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser werden für
              die Zwecke des Publikationsmediums nur insoweit verarbeitet, als es für dessen
              Darstellung und die Kommunikation zwischen Autoren und Lesern oder aus Gründen
              der Sicherheit erforderlich ist. Im Übrigen verweisen wir auf die
              Informationen zur Verarbeitung der Besucher unseres Publikationsmediums im
              Rahmen dieser Datenschutzhinweise.
            </p>
            <p>
              <strong>Kommentare und Beiträge</strong>: Wenn Nutzer Kommentare oder sonstige
              Beiträge hinterlassen, können ihre IP-Adressen auf Grundlage unserer
              berechtigten Interessen gespeichert werden. Das erfolgt zu unserer Sicherheit,
              falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt
              (Beleidigungen, verbotene politische Propaganda etc.). In diesem Fall können
              wir selbst für den Kommentar oder Beitrag belangt werden und sind daher an der
              Identität des Verfassers interessiert.
            </p>
            <p>
              Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten
              Interessen die Angaben der Nutzer zwecks Spamerkennung zu verarbeiten.
            </p>
            <p>
              Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen die
              IP-Adressen der Nutzer für deren Dauer zu speichern und Cookies zu verwenden,
              um Mehrfachabstimmungen zu vermeiden.
            </p>
            <p>
              Die im Rahmen der Kommentare und Beiträge mitgeteilten Informationen zur
              Person, etwaige Kontakt- sowie Webseiteninformationen als auch die
              inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer dauerhaft
              gespeichert.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen
                und Kundenservice, Feedback (z.B. Sammeln von Feedback via Online-Formular),
                Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
                Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m182">Kontaktaufnahme</h2>
            <p>
              Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon, Chat
              oder via soziale Medien) werden die Angaben der anfragenden Personen
              verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
              angefragter Maßnahmen erforderlich ist.
            </p>
            <p>
              Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder
              vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen
              Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen
              auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche
                Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
                Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m391">Kommunikation via Messenger</h2>
            <p>
              Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher darum,
              die nachfolgenden Hinweise zur Funktionsfähigkeit der Messenger, zur
              Verschlüsselung, zur Nutzung der Metadaten der Kommunikation und zu Ihren
              Widerspruchsmöglichkeiten zu beachten.
            </p>
            <p>
              Diese Website nutzt Tidio, eine Chat-Plattform, die Nutzer mit dem Kundensupport von LinkFive.io
              verbindet. Wir erheben die E-Mail-Adresse nur mit der Zustimmung der Nutzer, um den Chat zu starten.
              Die ausgetauschten Nachrichten und Daten werden innerhalb der Tidio-Anwendung gespeichert.
              LinkFive.io verwendet diese Nachrichten oder Daten nur, um die registrierten Probleme oder Anfragen der
              Nutzer zu bearbeiten.
              Ihre personenbezogenen Daten werden in Übereinstimmung mit der Allgemeinen Datenschutzverordnung (DSGVO)
              verarbeitet und übermittelt.
            </p>
            <p>
              Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder E-Mail,
              kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten Kontaktmöglichkeiten
              oder die innerhalb unseres Onlineangebotes angegebenen Kontaktmöglichkeiten.
            </p>
            <p>
              Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt
              Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
              Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder)
              von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der
              Nachrichten nicht einsehbar ist, nicht einmal durch die Messenger-Anbieter
              selbst. Sie sollten immer eine aktuelle Version der Messenger mit aktivierter
              Verschlüsselung nutzen, damit die Verschlüsselung der Nachrichteninhalte
              sichergestellt ist.
            </p>
            <p>
              Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass die
              Anbieter der Messenger zwar nicht den Inhalt einsehen, aber in Erfahrung
              bringen können, dass und wann Kommunikationspartner mit uns kommunizieren
              sowie technische Informationen zum verwendeten Gerät der Kommunikationspartner
              und je nach Einstellungen ihres Gerätes auch Standortinformationen (sogenannte
              Metadaten) verarbeitet werden.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir
              Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um eine
              Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten
              deren Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten
              und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir Messenger im
              Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung
              als eine vertragliche Maßnahme und im Fall anderer Interessenten und
              Kommunikationspartner auf Grundlage unserer berechtigten Interessen an einer
              schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
              Kommunikationspartner an der Kommunikation via Messenger. Ferner weisen wir
              Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten ohne Ihre
              Einwilligung nicht erstmalig an die Messenger übermitteln.
            </p>
            <p>
              <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können jederzeit eine
              erteilte Einwilligung widerrufen und der Kommunikation mit uns via Messenger
              jederzeit widersprechen. Im Fall der Kommunikation via Messenger löschen wir
              die Nachrichten entsprechend unseren generellen Löschrichtlinien (d.h. z.B.,
              wie oben beschrieben, nach Ende vertraglicher Beziehungen, im Kontext von
              Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen können,
              etwaige Auskünfte der Kommunikationspartner beantwortet zu haben, wenn kein
              Rückbezug auf eine vorhergehende Konversation zu erwarten ist und der Löschung
              keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
            </p>
            <p>
              <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong> Zum
              Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen Ihrer
              Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten. Das ist
              der Fall, wenn z.B. Vertragsinterna besonderer Geheimhaltung bedürfen oder
              eine Antwort über Messenger den formellen Ansprüchen nicht genügt. In solchen
              Fällen verweisen wir Sie auf adäquatere Kommunikationswege.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation,
                Direktmarketing (z.B. per E-Mail oder postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m735">
              Videokonferenzen, Onlinemeetings, Webinare und Bildschirm-Sharing
            </h2>
            <p>
              Wir setzen Plattformen und Anwendungen anderer Anbieter (nachfolgend
              bezeichnet als “Drittanbieter”) zu Zwecken der Durchführung von Video und
              Audio-Konferenzen, Webinaren und sonstigen Arten von Video und Audio-Meetings
              ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten wir die
              gesetzlichen Vorgaben.
            </p>
            <p>
              In diesem Rahmen werden Daten der Kommunikationsteilnehmer verarbeitet und auf
              den Servern der Drittanbieter gespeichert, soweit diese Bestandteil von
              Kommunikationsvorgängen mit uns sind. Zu diesen Daten können insbesondere
              Anmelde- und Kontaktdaten, visuelle sowie stimmliche Beiträge sowie Eingaben
              in Chats und geteilte Bildschirminhalte gehören.
            </p>
            <p>
              Sofern Nutzer im Rahmen der Kommunikation, Geschäfts- oder anderen Beziehungen
              mit uns auf die Drittanbieter, bzw. deren Software oder Plattformen verwiesen
              werden, können die Drittanbieter Nutzungsdaten und Metadaten zu
              Sicherheitszwecken, Serviceoptimierung oder Marketingzwecken verarbeiten. Wir
              bitten daher, die Datenschutzhinweise der jeweiligen Drittanbieter zu
              beachten.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter oder bestimmter Funktionen
              bitten (z. B. Einverständnis mit einer Aufzeichnung von Gesprächen), ist die
              Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann deren Einsatz
              ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz
              der Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die
              Daten der Nutzer auf Grundlage unserer berechtigten Interessen an einer
              effizienten und sicheren Kommunikation mit unseren unser
              Kommunikationspartnern verarbeitet. In diesem Zusammenhang möchten wir Sie
              zusätzlich auf die Informationen zur Verwendung von Cookies in dieser
              Datenschutzerklärung verweisen.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen
                und Kundenservice, Kontaktanfragen und Kommunikation, Büro- und
                Organisationsverfahren.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m29">Cloud-Dienste</h2>
            <p>
              Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter
              ausgeführte Softwaredienste (sogenannte "Cloud-Dienste", auch bezeichnet als
              "Software as a Service") für die folgenden Zwecke: Dokumentenspeicherung und
              Verwaltung, Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen und
              Präsentationen, Austausch von Dokumenten, Inhalten und Informationen mit
              bestimmten Empfängern oder Veröffentlichung von Webseiten, Formularen oder
              sonstigen Inhalten und Informationen sowie Chats und Teilnahme an Audio- und
              Videokonferenzen.
            </p>
            <p>
              In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter gespeichert
              werden, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im
              Rahmen dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen Daten können insbesondere
              Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren
              Inhalte gehören. Die Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten, die von
              ihnen zu Sicherheitszwecken und zur Serviceoptimierung verwendet werden.
            </p>
            <p>
              Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche Webseiten Formulare
              o.a. Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten der Nutzer für
              Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der Mediensteuerung) zu merken,
              speichern.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir um eine Einwilligung in den Einsatz der
              Cloud-Dienste bitten, ist die Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann deren Einsatz
              ein Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Cloud-Dienste in diesem
              Rahmen vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten
              Interessen (d.h., Interesse an effizienten und sicheren Verwaltungs- und Kollaborationsprozessen)
              verarbeitet
            </p>
            <div>
              <ul className="m-elements">
                <li>
                  <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                  Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                  Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                  Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                  Geräte-Informationen, IP-Adressen).
                </li>
                <li>
                  <strong>Betroffene Personen:</strong> Kunden, Beschäftigte (z.B.
                  Angestellte, Bewerber, ehemalige Mitarbeiter), Interessenten,
                  Kommunikationspartner.
                </li>
                <li>
                  <strong>Zwecke der Verarbeitung:</strong> Büro- und
                  Organisationsverfahren.
                </li>
                <li>
                  <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit.
                  a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                  S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
                  DSGVO).
                </li>
              </ul>
              <h3>Eingesetzte Dienste und Diensteanbieter:</h3>
              <ul className="m-elements">
                <li>
                  <strong>Dropbox:</strong> Cloud-Speicher-Dienste; Dienstanbieter: Dropbox, Inc., 333 Brannan Street,
                  San Francisco, California 94107, USA; Website: <a href="https://www.dropbox.com/de" target="_blank"
                                                                    rel="noreferrer"
                >https://www.dropbox.com/de</a>; Datenschutzerklärung: <a href="https://www.dropbox.com/privacy"
                                                                          target="_blank" rel="noreferrer"
                >https://www.dropbox.com/privacy</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung
                  von Daten in den USA): <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000GnCLAA0&status=Active" target="_blank"
                  rel="noreferrer"
                >https://www.privacyshield.gov/participant?id=a2zt0000000GnCLAA0&status=Active</a>;
                  Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern): <a
                  href="https://www.dropbox.com/terms/business-agreement-2016" target="_blank" rel="noreferrer"
                >https://www.dropbox.com/terms/business-agreement-2016</a>.
                </li>
                <li>
                  <strong>Google Cloud-Dienste:</strong> Cloud-Speicher-Dienste; Dienstanbieter: Google Ireland Limited,
                  Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre
                  Parkway, Mountain View, CA 94043, USA; Website: <a href="https://cloud.google.com/" target="_blank"
                                                                     rel="noreferrer"
                >https://cloud.google.com/</a>; Datenschutzerklärung: <a href="https://www.google.com/policies/privacy"
                                                                         target="_blank" rel="noreferrer"
                >https://www.google.com/policies/privacy</a>, Sicherheitshinweise: <a
                  href="https://cloud.google.com/security/privacy" target="_blank" rel="noreferrer"
                >https://cloud.google.com/security/privacy</a>; Privacy Shield (Gewährleistung Datenschutzniveau bei
                  Verarbeitung von Daten in den USA): <a
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive"
                  target="_blank" rel="noreferrer"
                >https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive</a>;
                  Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern): <a
                  href="https://cloud.google.com/terms/data-processing-terms" target="_blank" rel="noreferrer"
                >https://cloud.google.com/terms/data-processing-terms</a>; Zusätzliche Hinweise zum Datenschutz: <a
                  href="https://cloud.google.com/terms/data-processing-terms" target="_blank" rel="noreferrer"
                >https://cloud.google.com/terms/data-processing-terms</a>.
                </li>
              </ul>
            </div>
            <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
            <p>
              Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen
              (nachfolgend "Newsletter“) nur mit der Einwilligung der Empfänger oder einer
              gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen
              Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer
              maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren
              Leistungen und uns.
            </p>
            <p>
              Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn
              Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen,
              zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern
              diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
            </p>
            <p>
              <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter
              erfolgt grundsätzlich in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie
              erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer
              Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand
              mit fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter
              werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen
              Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
              und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die
              Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten
              protokolliert.
            </p>
            <p>
              <strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die
              ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
              berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
              gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird
              auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
              individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
              ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten
              zur dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung
              der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
              "Blocklist") vor.
            </p>
            <p>
              Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer
              berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen
              Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
              beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an
              einem effizienten und sicheren Versandsystem.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter
              erfolgt auf Grundlage einer Einwilligung der Empfänger oder, falls eine
              Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten
              Interessen am Direktmarketing, sofern und soweit diese gesetzlich, z.B. im
              Fall von Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister
              mit dem Versand von E-Mails beauftragen, geschieht dies auf der Grundlage
              unserer berechtigten Interessen. Das Registrierungsverfahren wird auf der
              Grundlage unserer berechtigten Interessen aufgezeichnet, um nachzuweisen, dass
              es in Übereinstimmung mit dem Gesetz durchgeführt wurde.
            </p>
            <p>
              <strong>Inhalte:</strong> Informationen zu uns, unseren Leistungen, Aktionen
              und Angeboten.
            </p>
            <p><strong>Messung von Öffnungs- und Klickraten</strong>:</p>
            <p>
              Die Newsletter enthalten einen sogenannte "web-beacon“, d.h., eine
              pixelgroße Datei, die beim Öffnen des Newsletters von unserem Server, bzw.,
              sofern wir einen Versanddienstleister einsetzen, von dessen Server abgerufen
              wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie
              Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und der
              Zeitpunkt des Abrufs, erhoben.
            </p>
            <p>
              Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen Daten
              oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse
              bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob
              die Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese
              Informationen können aus technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es
              ist jedoch weder unser Bestreben noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer
              zu beobachten. Die Auswertungen dienen uns vielmehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen
              und unsere Inhalte an sie anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer
              Nutzer zu versenden.
            </p>
            <p>
              Die Auswertung des Newsletters und die Erfolgsmessung erfolgen, vorbehaltlich einer ausdrücklichen
              Einwilligung der Nutzer, auf Grundlage unserer berechtigten Interessen zu Zwecken des Einsatzes eines
              nutzerfreundlichen sowie sicheren Newslettersystems, welches sowohl unseren geschäftlichen Interessen
              dient, als auch den Erwartungen der Nutzer entspricht.
            </p>
            <p>
              Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das gesamte
              Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden.
            </p>
            <p>
              <strong>Voraussetzung der Inanspruchnahme kostenloser Leistungen:</strong> Die Einwilligungen in den
              Versand von Mailings kann als Voraussetzung zur Inanspruchnahme kostenloser Leistungen (z.B. Zugang zu
              bestimmten Inhalten oder Teilnahme an bestimmten Aktionen) abhängig gemacht werden. Sofern die Nutzer die
              kostenlose Leistung in Anspruch nehmen möchten, ohne sich zum Newsletter anzumelden, bitten wir Sie um
              eine Kontaktaufnahme.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen),
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten).
              </li>
              <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail
                oder postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
              <li>
                <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang
                unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen,
                bzw. dem weiteren Empfang widersprechen. Einen Link zur Kündigung des
                Newsletters finden Sie entweder am Ende eines jeden Newsletters oder können
                sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail,
                hierzu nutzen.
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>SendinBlue:</strong> E-Mail-Marketing-Plattform; Dienstanbieter:
                SendinBlue SAS, 55, rue d’Amsterdam, 75008 Paris, Frankreich; Website:
                <a href="https://de.sendinblue.com/" target="_blank" rel="noreferrer"
                >https://de.sendinblue.com/</a
                >; Datenschutzerklärung:
                <a href="https://www.sendinblue.com/legal/privacypolicy/" target="_blank" rel="noreferrer"
                >https://www.sendinblue.com/legal/privacypolicy/</a
                >.
              </li>
            </ul>
            <h2 id="m638">Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
              Kommunikation, die über diverse Kanäle, wie z.B. E-Mail, Telefon, Post oder
              Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
            </p>
            <p>
              Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen
              oder der werblichen Kommunikation jederzeit zu widersprechen.
            </p>
            <p>
              Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der Einwilligung
              erforderlichen Daten bis zu drei Jahren auf Grundlage unserer berechtigten
              Interessen speichern, bevor wir sie löschen. Die Verarbeitung dieser Daten
              wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
              individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
              ehemalige Bestehen einer Einwilligung bestätigt wird.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern).
              </li>
              <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail
                oder postalisch).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m408">Umfragen und Befragungen</h2>
            <p>
              Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
              "Befragungen") werden anonym ausgewertet. Eine Verarbeitung personenbezogener
              Daten erfolgt nur insoweit, als dies zu Bereitstellung und technischen
              Durchführung der Umfragen erforderlich ist (z.B. Verarbeitung der IP-Adresse,
              um die Umfrage im Browser des Nutzers darzustellen oder mithilfe eines
              temporären Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu
              ermöglichen) oder Nutzer eingewilligt haben.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Teilnehmer um
              eine Einwilligung in die Verarbeitung iherer Daten bitten, ist diese
              Rechtsgrundlage der Verarbeitung, ansonsten erfolgt die Verarbeitung der Daten
              der Teilnehmer auf Grundlage unserer berechtigten Interessen an der
              Durchführung einer objektiven Umfrage.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
                Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen),
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation,
                Direktmarketing (z.B. per E-Mail oder postalisch), Tracking (z.B.
                interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Feedback
                (z.B. Sammeln von Feedback via Online-Formular).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
            <p>
              Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der Auswertung
              der Besucherströme unseres Onlineangebotes und kann Verhalten, Interessen oder
              demographische Informationen zu den Besuchern, wie z.B. das Alter oder das
              Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe der Reichweitenanalyse
              können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot oder dessen
              Funktionen oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
              einladen. Ebenso können wir nachvollziehen, welche Bereiche der Optimierung
              bedürfen.
            </p>
            <p>
              Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
              unterschiedliche Versionen unseres Onlineangebotes oder seiner Bestandteile zu
              testen und optimieren.
            </p>
            <p>
              Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in einer Datei
              (sogenannte "Cookie") gespeichert oder ähnliche Verfahren mit dem gleichen
              Zweck genutzt werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
              besuchte Webseiten und dort genutzte Elemente und technische Angaben, wie der
              verwendete Browser, das verwendete Computersystem sowie Angaben zu
              Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer Standortdaten
              eingewilligt haben, können je nach Anbieter auch diese verarbeitet werden.
            </p>
            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir
              ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch Kürzung der
              IP-Adresse) zum Schutz der Nutzer. Generell werden die im Rahmen von
              Webanalyse, A/B-Testings und Optimierung keine Klardaten der Nutzer (wie z.B.
              E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als
              auch die Anbieter der eingesetzten Software kennen nicht die tatsächliche
              Identität der Nutzer, sondern nur den für Zwecke der jeweiligen Verfahren in
              deren Profilen gespeicherten Angaben.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
              der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
              Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
              effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
              zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>
            <p><strong>Firebase</strong>:</p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                (z.B. Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z.B.
                Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B.
                interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
                Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen),
                Profiling (Erstellen von Nutzerprofilen), Servermonitoring und
                Fehlererkennung.
              </li>
              <li>
                <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                IP-Adresse).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>Google Analytics:</strong> Reichweitenmessung und Webanalyse;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin
                4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; Website:
                <a
                  href="https://marketingplatform.google.com/intl/de/about/analytics/"
                  target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com/intl/de/about/analytics/</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
            </ul>
            <h2 id="m264">Onlinemarketing</h2>
            <p>
              Wir verarbeiten personenbezogene Daten zu Zwecken des Onlinemarketings,
              worunter insbesondere die Vermarktung von Werbeflächen oder Darstellung von
              werbenden und sonstigen Inhalten (zusammenfassend als "Inhalte" bezeichnet)
              anhand potentieller Interessen der Nutzer sowie die Messung ihrer Effektivität
              fallen kann.
            </p>
            <p>
              Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in einer Datei
              (sogenannte "Cookie") gespeichert oder ähnliche Verfahren genutzt, mittels
              derer die für die Darstellung der vorgenannten Inhalte relevante Angaben zum
              Nutzer gespeichert werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
              besuchte Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner
              und technische Angaben, wie der verwendete Browser, das verwendete
              Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die
              Erhebung ihrer Standortdaten eingewilligt haben, können auch diese verarbeitet
              werden.
            </p>
            <p>
              Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch nutzen wir
              zur Verfügung stehende IP-Masking-Verfahren (d.h., Pseudonymisierung durch
              Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden im Rahmen des
              Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen
              oder Namen) gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter
              der Onlinemarketingverfahren kennen nicht die tatsächlich Identität der
              Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.
            </p>
            <p>
              Die Angaben in den Profilen werden im Regelfall in den Cookies oder mittels
              ähnlicher Verfahren gespeichert. Diese Cookies können später generell auch auf
              anderen Webseiten die dasselbe Onlinemarketingverfahren einsetzen, ausgelesen
              und zu Zwecken der Darstellung von Inhalten analysiert als auch mit weiteren
              Daten ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
              gespeichert werden.
            </p>
            <p>
              Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist der
              Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks sind, dessen
              Onlinemarketingverfahren wir einsetzen und das Netzwerk die Profile der Nutzer
              mit den vorgenannten Angaben verbindet. Wir bitten darum, zu beachten, dass
              Nutzer mit den Anbietern zusätzliche Abreden, z.B. durch Einwilligung im
              Rahmen der Registrierung, treffen können.
            </p>
            <p>
              Wir erhalten grundsätzlich nur Zugang zu zusammengefassten Informationen über
              den Erfolg unserer Werbeanzeigen. Jedoch können wir im Rahmen sogenannter
              Konversionsmessungen prüfen, welche unserer Onlinemarketingverfahren zu einer
              sogenannten Konversion geführt haben, d.h. z.B., zu einem Vertragsschluss mit
              uns. Die Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
              Marketingmaßnahmen verwendet.
            </p>
            <p>
              Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
              verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert werden.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
              der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
              Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
              effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
              zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>
            <p>
              <strong>Zielgruppenbildung mit Google Analytics</strong>: Wir setzen Google
              Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner
              Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein
              Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale
              (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten
              Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog.
              "Remarketing-“, bzw. "Google-Analytics-Audiences“). Mit Hilfe der Remarketing
              Audiences möchten wir auch sicherstellen, dass unsere Anzeigen dem
              potentiellen Interesse der Nutzer entsprechen
            </p>
            <p>
              <strong>Facebook-Pixel und Zielgruppenbildung (Custom Audiences)</strong>:
            </p>
            <p>
              Mit Hilfe des Facebook-Pixels (oder vergleichbarer Funktionen, zur
              Übermittlung von Event-Daten oder Kontaktinformationen mittels von
              Schnittstellen in Apps) ist es Facebook zum einen möglich, die Besucher
              unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen
              (sogenannte "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das
              Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen
              Nutzern bei Facebook und innerhalb der Dienste der mit Facebook
              kooperierenden Partner (so genanntes "Audience Network"
              <a href="https://www.facebook.com/audiencenetwork/" target="_blank" rel="noreferrer"
              >https://www.facebook.com/audiencenetwork/</a
              >
              ) anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben
              oder die bestimmte Merkmale (z.B. Interesse an bestimmten Themen oder
              Produkten, die anhand der besuchten Webseiten ersichtlich werden) aufweisen,
              die wir an Facebook übermitteln (sogenannte "Custom Audiences“). Mit Hilfe
              des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads
              dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend
              wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der
              Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
              nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere
              Webseite weitergeleitet wurden (sogenannte "Konversionsmessung“).
            </p>
            <p>
              <strong>Erweiterter Abgleich für das Facebook-Pixel:</strong> Beim Einsatz des Facebook-Pixels wird die
              Zusatzfunktion "erweiterter Abgleich“ eingesetzt. In diesem Zusammenhang werden Daten, wie z.B.
              E-Mail-Adressen oder Facebook-IDs der Nutzer, zur Bildung von Zielgruppen (verschlüsselt) an Facebook
              übermittelt.
            </p>
            <p>
              <strong>Facebook - Zielgruppen Bildung via Datenupload:</strong> Hochladen von Daten, wie z.B.
              Telefonnummern, E-Mail-Adressen oder Facebook-IDs bei der Plattform Facebook. Die Daten werden hierbei
              verschlüsselt. Der Hochladevorgang dient nur dazu, um den Inhabern der Daten oder Personen, deren
              Nutzerprofilen etwaigen Nutzerprofilen der Inhaber der Daten bei Facebook entsprechen, Werbeanzeigen
              anzuzeigen. Wir möchten damit sicherstellen, dass die Anzeigen nur Nutzern angezeigt werden, die ein
              Interesse an unseren Informationen und Leistungen haben.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Angaben zur
                geografischen Position eines Gerätes oder einer Person), Sozialdaten (Daten,
                die dem Sozialgeheimnis unterliegen und z.B. von Sozialversicherungsträgern,
                Sozialhilfeträger oder Versorgungsbehörden verarbeitet werden.), Event-Daten
                (Facebook) ("Event-Daten" sind Daten, die z. B. via Facebook-Pixel (via Apps
                oder auf anderen Wegen) von uns an Facebook übermittelt werden können und
                sich auf Personen oder deren Handlungen beziehen; Zu den Daten gehören z. B.
                Angaben über Besuche auf Websites, Interaktionen mit Inhalten, Funktionen,
                Installationen von Apps, Käufe von Produkten, etc.; die Event-Daten werden
                zwecks Bildung von Zielgruppen für Inhalte und Werbeinformationen (Custom
                Audiences) verarbeitet; Event Daten beinhalten nicht die eigentlichen
                Inhalte (wie z. B. verfasste Kommentare), keine Login-Informationen und
                keine Kontaktinformationen (also keine Namen, E-Mail-Adressen und
                Telefonnummern). Event Daten werden durch Facebook nach maximal zwei Jahren
                gelöscht, die aus ihnen gebildeten Zielgruppen mit der Löschung unseres
                Facebook-Kontos).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten), Interessenten, Kunden, Beschäftigte (z.B. Angestellte,
                Bewerber, ehemalige Mitarbeiter), Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Tracking (z.B.
                interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
                Remarketing, Konversionsmessung (Messung der Effektivität von
                Marketingmaßnahmen), Interessenbasiertes und verhaltensbezogenes Marketing,
                Profiling (Erstellen von Nutzerprofilen), Reichweitenmessung (z.B.
                Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Zielgruppenbildung
                (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder sonstige
                Ausgabe von Inhalten), Cross-Device Tracking (geräteübergreifende
                Verarbeitung von Nutzerdaten für Marketingzwecke).
              </li>
              <li>
                <strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung der
                IP-Adresse).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
              <li>
                <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Wir verweisen auf die
                Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
                angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out"). Sofern keine
                explizite Opt-Out-Möglichkeit angegeben wurde, besteht zum einen die
                Möglichkeit, dass Sie Cookies in den Einstellungen Ihres Browsers
                abschalten. Hierdurch können jedoch Funktionen unseres Onlineangebotes
                eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
                Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete gerichtet
                angeboten werden: a) Europa:
                <a href="https://www.youronlinechoices.eu" target="_blank" rel="noreferrer"
                >https://www.youronlinechoices.eu</a
                >. b) Kanada:
                <a href="https://www.youradchoices.ca/choices" target="_blank" rel="noreferrer"
                >https://www.youradchoices.ca/choices</a
                >. c) USA:
                <a href="https://www.aboutads.info/choices" target="_blank" rel="noreferrer"
                >https://www.aboutads.info/choices</a
                >. d) Gebietsübergreifend:
                <a href="https://optout.aboutads.info" target="_blank" rel="noreferrer"
                >https://optout.aboutads.info</a
                >.
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>Google Tag Manager:</strong> Google Tag Manager ist eine Lösung, mit
                der wir sog. Website-Tags über eine Oberfläche verwalten und so andere
                Dienste in unser Onlineangebot einbinden können (hierzu wird auf weitere
                Angaben in dieser Datenschutzerklärung verwiesen). Mit dem Tag Manager
                selbst (welches die Tags implementiert) werden daher z. B. noch keine
                Profile der Nutzer erstellt oder Cookies gespeichert. Google erfährt
                lediglich die IP-Adresse des Nutzers, was notwendig ist, um den Google Tag
                Manager auszuführen. Dienstanbieter: Google Ireland Limited, Gordon House,
                Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Analytics:</strong> Onlinemarketing und Webanalyse;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin
                4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; Website:
                <a
                  href="https://marketingplatform.google.com/intl/de/about/analytics/"
                  target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com/intl/de/about/analytics/</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer"
                >https://tools.google.com/dlpage/gaoptout?hl=de</a
                >, Einstellungen für die Darstellung von Werbeeinblendungen:
                <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer"
                >https://adssettings.google.com/authenticated</a
                >.
              </li>
              <li>
                <strong>Zielgruppenbildung mit Google Analytics:</strong> . Dienstanbieter:
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland,
                parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Ads und Konversionsmessung:</strong> Wir nutzen das
                Onlinemarketingverfahren "Google Ads", um Anzeigen im Google-Werbe-Netzwerk
                zu platzieren (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.),
                damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an den
                Anzeigen haben. Ferner messen wir die Konversion der Anzeigen. Wir erfahren
                jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige
                geklickt haben und zu einer mit einem sog "Conversion-Tracking-Tag"
                versehenen Seite weitergeleitet wurden. Wir selbst erhalten jedoch keine
                Informationen, mit denen sich Nutzer identifizieren lassen. Dienstanbieter:
                Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland,
                parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Ad Manager:</strong> Wir nutzen die "Google Marketing
                Platform" (und Dienste wie z.B. "Google Ad Manager"), um Anzeigen im
                Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos,
                auf Webseiten, etc.). Die Google Marketing Platform zeichnet sich dadurch
                aus, dass Anzeigen in Echtzeit anhand mutmaßlicher Interessen der Nutzer
                angezeigt werden. Dies erlaubt uns Anzeigen für und innerhalb unseres
                Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu
                präsentieren, die potentiell deren Interessen entsprechen. Falls einem
                Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf
                anderen Onlineangeboten interessiert hat, spricht man hierbei vom
                "Remarketing“. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Adsense mit personalisierten Anzeigen:</strong> Wir nutzen
                den Dienst Google Adsense mit personalisierten Anzeigen, mit dessen Hilfe
                innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden und wir für
                deren Einblendung oder sonstige Nutzung eine Entlohnung erhalten. ;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin
                4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain
                View, CA 94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Adsense mit nicht-personalisierten Anzeigen:</strong> Wir
                nutzen den Dienst Google Adsense mit nicht-personalisierten Anzeigen, mit
                dessen Hilfe innerhalb unseres Onlineangebotes Anzeigen eingeblendet werden
                und wir für deren Einblendung oder sonstige Nutzung eine Entlohnung
                erhalten. ; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA; Website:
                <a href="https://marketingplatform.google.com" target="_blank" rel="noreferrer"
                >https://marketingplatform.google.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
            </ul>
            <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten
              in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu
              kommunizieren oder um Informationen über uns anzubieten.
            </p>
            <p>
              Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der
              Europäischen Union verarbeitet werden können. Hierdurch können sich für die
              Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer
              erschwert werden könnte.
            </p>
            <p>
              Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall
              für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des
              Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer
              Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet
              werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu
              schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
              Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
              in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert
              werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von
              den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die
              Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt
              sind).
            </p>
            <p>
              Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der
              Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
              Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
            </p>
            <p>
              Auch im Fall von Auskunftsanfragen und der Geltendmachung von
              Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den
              Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
              Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
              ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
              können Sie sich an uns wenden.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten).
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation,
                Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
                Cookies), Remarketing.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S.
                1 lit. f. DSGVO).
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter: LinkedIn
                Ireland Unlimited Company, Wilton Place, Dublin 2, Irland; Website:
                <a href="https://www.linkedin.com" target="_blank" rel="noreferrer"
                >https://www.linkedin.com</a
                >; Datenschutzerklärung:
                <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer"
                >https://www.linkedin.com/legal/privacy-policy</a
                >; Widerspruchsmöglichkeit (Opt-Out):
                <a
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                  target="_blank" rel="noreferrer"
                >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a
                >.
              </li>
              <li>
                <strong>Twitter:</strong> Soziales Netzwerk; Dienstanbieter: Twitter
                International Company, One Cumberland Place, Fenian Street, Dublin 2 D02
                AX07, Irland, Mutterunternehmen: Twitter Inc., 1355 Market Street, Suite
                900, San Francisco, CA 94103, USA; Datenschutzerklärung:
                <a href="https://twitter.com/de/privacy" target="_blank" rel="noreferrer"
                >https://twitter.com/de/privacy</a
                >, (Einstellungen)
                <a href="https://twitter.com/personalization" target="_blank" rel="noreferrer"
                >https://twitter.com/personalization</a
                >.
              </li>
            </ul>
            <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
            <p>
              Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von
              den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als
              "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken,
              Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend
              einheitlich bezeichnet als "Inhalte”).
            </p>
            <p>
              Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die
              IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte
              nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
              Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur
              solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
              zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte
              Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für
              statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können
              Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite,
              ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf
              dem Gerät der Nutzer gespeichert werden und unter anderem technische
              Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten,
              zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
              enthalten als auch mit solchen Informationen aus anderen Quellen verbunden
              werden.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
              der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
              Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
              effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
              zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
                (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Angaben zur
                geografischen Position eines Gerätes oder einer Person), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Bestandsdaten (z.B. Namen, Adressen),
                Kontaktdaten (z.B. E-Mail, Telefonnummern).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer
                von Onlinediensten), Kommunikationspartner.
              </li>
              <li>
                <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
                Onlineangebotes und Nutzerfreundlichkeit, Erbringung vertragliche Leistungen
                und Kundenservice, Kontaktanfragen und Kommunikation, Tracking (z.B.
                interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
                Interessenbasiertes und verhaltensbezogenes Marketing, Profiling (Erstellen
                von Nutzerprofilen), Sicherheitsmaßnahmen, Verwaltung und Beantwortung von
                Anfragen.
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S.
                1 lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO),
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
                DSGVO).
              </li>
            </ul>
            <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
            <ul className="m-elements">
              <li>
                <strong>Font Awesome:</strong> Darstellung von Schriftarten und Symbolen;
                Dienstanbieter: Fonticons, Inc. ,6 Porter Road Apartment 3R, Cambridge, MA
                02140, USA; Website:
                <a href="https://fontawesome.com/" target="_blank" rel="noreferrer"
                >https://fontawesome.com/</a
                >; Datenschutzerklärung:
                <a href="https://fontawesome.com/privacy" target="_blank" rel="noreferrer"
                >https://fontawesome.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts")
                des Anbieters Google ein, wobei die Daten der Nutzer allein zu Zwecken der
                Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die
                Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer
                technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten,
                deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher
                lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; Website:
                <a href="https://fonts.google.com/" target="_blank" rel="noreferrer"
                >https://fonts.google.com/</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >.
              </li>
              <li>
                <strong>Google Maps:</strong> Wir binden die Landkarten des Dienstes “Google
                Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können
                insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch
                nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen
                ihrer Mobilgeräte vollzogen), erhoben werden; Dienstanbieter: Google Ireland
                Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
                Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                Website:
                <a href="https://cloud.google.com/maps-platform" target="_blank" rel="noreferrer"
                >https://cloud.google.com/maps-platform</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer"
                >https://tools.google.com/dlpage/gaoptout?hl=de</a
                >, Einstellungen für die Darstellung von Werbeeinblendungen:
                <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer"
                >https://adssettings.google.com/authenticated</a
                >.
              </li>
              <li>
                <strong>Twitter-Plugins und -Inhalte:</strong> Twitter Plugins und
                -Schaltflächen - Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte
                und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
                innerhalb von Twitter teilen können. Dienstanbieter: Twitter International
                Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland,
                Mutterunternehmen: Twitter Inc., 1355 Market Street, Suite 900, San
                Francisco, CA 94103, USA; Website:
                <a href="https://twitter.com/de" target="_blank" rel="noreferrer">https://twitter.com/de</a>;
                Datenschutzerklärung:
                <a href="https://twitter.com/de/privacy" target="_blank" rel="noreferrer"
                >https://twitter.com/de/privacy</a
                >.
              </li>
              <li>
                <strong>YouTube-Videos:</strong> Videoinhalte; Dienstanbieter: Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; Website:
                <a href="https://www.youtube.com" target="_blank" rel="noreferrer">https://www.youtube.com</a
                >; Datenschutzerklärung:
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"
                >https://policies.google.com/privacy</a
                >; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer"
                >https://tools.google.com/dlpage/gaoptout?hl=de</a
                >, Einstellungen für die Darstellung von Werbeeinblendungen:
                <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer"
                >https://adssettings.google.com/authenticated</a
                >.
              </li>
              <li>
                <strong>Vimeo:</strong> Videoinhalte; Dienstanbieter: Vimeo Inc., Attention:
                Legal Department, 555 West 18th Street New York, New York 10011, USA;
                Website: <a href="https://vimeo.com" target="_blank" rel="noreferrer">https://vimeo.com</a>;
                Datenschutzerklärung:
                <a href="https://vimeo.com/privacy" target="_blank" rel="noreferrer"
                >https://vimeo.com/privacy</a
                >; Widerspruchsmöglichkeit (Opt-Out): Wir weisen darauf hin, dass Vimeo
                Google Analytics einsetzen kann und verweisen hierzu auf die
                Datenschutzerklärung (<a
                href="https://policies.google.com/privacy"
                target="_blank" rel="noreferrer"
              >https://policies.google.com/privacy</a
              >) sowie die Opt-Out-Möglichkeiten für Google-Analytics (<a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank" rel="noreferrer"
              >https://tools.google.com/dlpage/gaoptout?hl=de</a
              >) oder die Einstellungen von Google für die Datennutzung zu
                Marketingzwecken (<a href="https://adssettings.google.com/" target="_blank" rel="noreferrer"
              >https://adssettings.google.com/</a
              >).
              </li>
            </ul>
            <h2 id="m723">Planung, Organisation und Hilfswerkzeuge</h2>
            <p>
              Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter
              (nachfolgend bezeichnet als "Drittanbieter”) zu Zwecken der Organisation,
              Verwaltung, Planung sowie Erbringung unserer Leistungen ein. Bei der Auswahl
              der Drittanbieter und ihrer Leistungen beachten wir die gesetzlichen Vorgaben.
            </p>
            <p>
              In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den
              Servern der Drittanbieter gespeichert werden. Hiervon können diverse Daten
              betroffen sein, die wir entsprechend dieser Datenschutzerklärung verarbeiten.
              Zu diesen Daten können insbesondere Stammdaten und Kontaktdaten der Nutzer,
              Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören.
            </p>
            <p>
              Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen
              Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder Plattformen
              verwiesen werden, können die Drittanbieter Nutzungsdaten und Metadaten zu
              Sicherheitszwecken, zur Serviceoptimierung oder zu Marketingzwecken
              verarbeiten. Wir bitten daher darum, die Datenschutzhinweise der jeweiligen
              Drittanbieter zu beachten.
            </p>
            <p>
              <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren
              Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
              der Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein
              Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der
              Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten
              der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
              effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
              verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
              zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
            </p>
            <ul className="m-elements">
              <li>
                <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
                Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
                Geräte-Informationen, IP-Adressen).
              </li>
              <li>
                <strong>Betroffene Personen:</strong> Kommunikationspartner, Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten).
              </li>
              <li>
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a.
                DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
              </li>
            </ul>
            <h2 id="m12">Löschung von Daten</h2>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben
              gelöscht, sobald deren zur Verarbeitung erlaubten Einwilligungen widerrufen
              werden oder sonstige Erlaubnisse entfallen (z.B., wenn der Zweck der
              Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
              erforderlich sind).
            </p>
            <p>
              Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich
              zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf diese Zwecke
              beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke
              verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen
              Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
              einer anderen natürlichen oder juristischen Person erforderlich ist.
            </p>
            <p>
              Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im
              Rahmen der einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
            </p>
            <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
            <p>
              Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung
              zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen
              der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
              informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
              Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
              Benachrichtigung erforderlich wird.
            </p>
            <p>
              Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen
              von Unternehmen und Organisationen angeben, bitten wir zu beachten, dass die
              Adressen sich über die Zeit ändern können und bitten die Angaben vor
              Kontaktaufnahme zu prüfen.
            </p>
            <h2 id="m10">Rechte der betroffenen Personen</h2>
            <p>
              Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich
              insbesondere aus Art. 15 bis 21 DSGVO ergeben:
            </p>
            <ul>
              <li>
                <strong>Widerspruchsrecht:</strong> Sie haben das Recht, aus Gründen, die sich aus Ihrer
                besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit.
                e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
                diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben
                Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie
                betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
                einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                Direktwerbung in Verbindung steht.
              </li>
              <li>
                <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht,
                erteilte Einwilligungen jederzeit zu widerrufen.
              </li>
              <li>
                <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
                darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
                Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
                Daten entsprechend den gesetzlichen Vorgaben.
              </li>
              <li>
                <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
                gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden
                Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
                verlangen.
              </li>
              <li>
                <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie
                haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass
                Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
                Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der
                Daten zu verlangen.
              </li>
              <li>
                <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie
                betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
                gesetzlichen Vorgaben in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
                anderen Verantwortlichen zu fordern.
              </li>
              <li>
                <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach
                Maßgabe der gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde,
                insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres
                Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes Beschwerde
                einzulegen, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
              </li>
            </ul>
            <h2 id="m42">Begriffsdefinitionen</h2>
            <p>
              In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
              Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe sind
              dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die gesetzlichen
              Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen
              vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
            </p>
            <ul className="glossary">
              <li>
                <strong>Content Delivery Network (CDN):</strong> Ein "Content Delivery
                Network" (CDN) ist ein Dienst, mit dessen Hilfe Inhalte eines
                Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
                Programm-Skripte mit Hilfe regional verteilter und über das Internet
                verbundener Server, schneller und sicherer ausgeliefert werden können.
              </li>
              <li>
                <strong>Cross-Device Tracking:</strong> Das Cross-Device Tracking ist eine
                Form des Trackings, bei der Verhaltens- und Interessensinformationen der
                Nutzer geräteübergreifend in sogenannten Profilen erfasst werden, indem den
                Nutzern eine Onlinekennung zugeordnet wird. Hierdurch können die
                Nutzerinformationen unabhängig von verwendeten Browsern oder Geräten (z.B.
                Mobiltelefonen oder Desktopcomputern) im Regelfall für Marketingzwecke
                analysiert werden. Die Onlinekennung ist bei den meisten Anbietern nicht mit
                Klardaten, wie Namen, Postadressen oder E-Mail-Adressen, verknüpft.
              </li>
              <li>
                <strong>IP-Masking:</strong> Als "IP-Masking” wird eine Methode bezeichnet,
                bei der das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse,
                gelöscht wird, damit die IP-Adresse nicht mehr der eindeutigen
                Identifizierung einer Person dienen kann. Daher ist das IP-Masking ein
                Mittel zur Pseudonymisierung von Verarbeitungsverfahren, insbesondere im
                Onlinemarketing
              </li>
              <li>
                <strong>Interessenbasiertes und verhaltensbezogenes Marketing:</strong> Von
                interessens- und/oder verhaltensbezogenem Marketing spricht man, wenn
                potentielle Interessen von Nutzern an Anzeigen und sonstigen Inhalten
                möglichst genau vorbestimmt werden. Dies geschieht anhand von Angaben zu
                deren Vorverhalten (z.B. Aufsuchen von bestimmten Webseiten und Verweilen
                auf diesen, Kaufverhaltens oder Interaktion mit anderen Nutzern), die in
                einem sogenannten Profil gespeichert werden. Zu diesen Zwecken werden im
                Regelfall Cookies eingesetzt.
              </li>
              <li>
                <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch als
                "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit dem die
                Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im
                Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten, auf
                denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der
                Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen, ob die
                von uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren.
              </li>
              <li>
                <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle
                Informationen, die sich auf eine identifizierte oder identifizierbare
                natürliche Person (im Folgenden "betroffene Person“) beziehen; als
                identifizierbar wird eine natürliche Person angesehen, die direkt oder
                indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
                oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann,
                die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
                Person sind.
              </li>
              <li>
                <strong>Profiling:</strong> Als "Profiling“ wird jede Art der
                automatisierten Verarbeitung personenbezogener Daten bezeichnet, die darin
                besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte
                persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach
                Art des Profilings gehören dazu Informationen betreffend das Alter, das
                Geschlecht, Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und
                deren Inhalten, Einkaufsverhalten, soziale Interaktionen mit anderen
                Menschen) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
                Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf
                einer Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings werden
                häufig Cookies und Web-Beacons eingesetzt.
              </li>
              <li>
                <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch als Web
                Analytics bezeichnet) dient der Auswertung der Besucherströme eines
                Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
                bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit
                Hilfe der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu
                welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich
                interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an
                die Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der Reichweitenanalyse
                werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um
                wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung
                eines Onlineangebotes zu erhalten.
              </li>
              <li>
                <strong>Remarketing:</strong> Vom "Remarketing“ bzw. "Retargeting“ spricht
                man, wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich ein
                Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen
                Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu erinnern.
              </li>
              <li>
                <strong>Servermonitoring und Fehlererkennung:</strong> Mit Hilfe des
                Servermonitorings und der Fehlererkennung stellen wir die Verfügbarkeit und
                Integrität unseres Onlineangebotes sicher und verwenden die verarbeiteten
                Daten, um unser Onlineangebot technisch zu optimieren. Verarbeitet werden
                Leistungs-, Auslastungs- und vergleichbare technische Werte, welche Auskunft
                über die Stabilität und etwaige Auffälligkeiten unseres Onlineangebotes
                geben. Im Fall von Fehlern und Auffälligkeit werden einzelne Anfragen der
                Nutzer unseres Onlineangebotes erfasst, um Problemquellen zu identifizieren
                und zu beheben.
              </li>
              <li>
                <strong>Standortdaten:</strong> Standortdaten entstehen, wenn sich ein
                mobiles Gerät (oder ein anderes Gerät mit den technischen Voraussetzungen
                einer Standortbestimmung) mit einer Funkzelle, einem WLAN oder ähnlichen
                technischen Mittlern und Funktionen der Standortbestimmung, verbindet.
                Standortdaten dienen der Angabe, an welcher geografisch bestimmbaren
                Position der Erde sich das jeweilige Gerät befindet. Standortdaten können z.
                B. eingesetzt werden, um Kartenfunktionen oder andere von einem Ort
                abhängige Informationen darzustellen.
              </li>
              <li>
                <strong>Tracking:</strong> Vom "Tracking“ spricht man, wenn das Verhalten
                von Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im
                Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens-
                und Interessensinformationen in Cookies oder auf Servern der Anbieter der
                Trackingtechnologien gespeichert (sogenanntes Profiling). Diese
                Informationen können anschließend z.B. eingesetzt werden, um den Nutzern
                Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
              </li>
              <li>
                <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
                natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
              </li>
              <li>
                <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe
                automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und
                umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten,
                das Speichern, das Übermitteln oder das Löschen.
              </li>
              <li>
                <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung (bzw. "Custom
                Audiences“) spricht man, wenn Zielgruppen für Werbezwecke, z.B. Einblendung
                von Werbeanzeigen, bestimmt werden. So kann z.B. anhand des Interesses eines
                Nutzers an bestimmten Produkten oder Themen im Internet geschlussfolgert
                werden, dass dieser Nutzer sich für Werbeanzeigen für ähnliche Produkte oder
                den Onlineshop, in dem er die Produkte betrachtet hat, interessiert. Von
                "Lookalike Audiences“ (bzw. ähnlichen Zielgruppen) spricht man wiederum,
                wenn die als geeignet eingeschätzten Inhalte Nutzern angezeigt werden, deren
                Profile bzw. Interessen mutmaßlich den Nutzern, zu denen die Profile
                gebildet wurden, entsprechen. Zu Zwecken der Bildung von Custom Audiences
                und Lookalike Audiences werden im Regelfall Cookies und Web-Beacons
                eingesetzt.
              </li>
            </ul>
            <br/>
            <p className="seal">
              <a
                href="https://datenschutz-generator.de/?l=de"
                title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                target="_blank"
                rel="noopener noreferrer nofollow"
              >Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
                Schwenke</a
              >
            </p>


          </div>
        </section>
      </Layout>
    </>
  )
}